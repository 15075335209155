.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

svg {
  display: block;
  margin: 0 auto;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Document Report Fonts */

.sacramento-regular {
  font-family: "Sacramento", cursive;
  font-weight: 400;
  font-size: xx-large;
  font-style: normal;
}

.tangerine-bold {
  font-family: "Tangerine", cursive;
  font-weight: 700;
  font-size: 4rem;
  font-style: normal;

}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Report CSS - Gallery */

.image-gallery-icon {
  color: pink;
}

.image-gallery-thumbnail {
  border: 1px solid pink;
}


/* Report Images carousel */
.report-image-carousel {
  height: 200px;
 
}

@media only screen and (min-width: 600px) {
  .report-image-carousel {
    height: 400px;

  }
}

/* Medium devices (tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .report-image-carousel {
    height: 450px;
  }
}

/* Large devices (desktops, 1280px and up) */
@media only screen and (min-width: 1280px) {
  .report-image-carousel {
    height: 450px;
  }
}

/* Extra Large devices (large desktops, 1920px and up) */
@media only screen and (min-width: 1920px) {
  .report-image-carousel {
    max-height: 600px;
  }
}


/* Site animations */

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Animating of pages */
.fade-in-1 {
  animation: fadeIn 1s ease-in-out;
}

.fade-in-2 {
  animation: fadeIn 1s ease-in-out;
  animation-delay: 0.5s;
  animation-fill-mode: both;
}

.fade-in-3 {
  animation: fadeIn 1s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: both;
}

.fade-in-4 {
  animation: fadeIn 1s ease-in-out;
  animation-delay: 1.5s;
  animation-fill-mode: both;
}

.fade-in-5 {
  animation: fadeIn 1s ease-in-out;
  animation-delay: 2s;
  animation-fill-mode: both;
}

.hover-primary {
  background-color: #ffffff;
  color: black;
  transition: background-color 2s ease-in-out, color 2s ease-in-out; /* Slower transition */
  will-change: background-color, color;
}

/* Mouse over effect */
.hover-primary:hover {
  background-color: #79c001;
  color: white;
}

/* Concept Page image effect */
.cross-fade-image {
  transition: background-image 7s ease-in-out;
}

